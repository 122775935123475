import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Image,
  Typography,
} from '@kitalulus/web-ui-kit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '~/components/Icons/CloseIcon';
import { useAppDispatch } from '~/hooks/use-store';
import { closeDialog } from '~/store/views/dialogs-slice';
import { VacancyDraftAlreadyPublishedDialogProps } from './';

const VacancyDraftAlreadyPublishedDialog = (
  props: VacancyDraftAlreadyPublishedDialogProps,
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    dispatch(closeDialog('vacancyDraftAlreadyPublishedDialog'));
  }, []);

  return (
    <Dialog
      data-test-id="ctVcanaycDraftAlreadyPublishedDialog"
      sx={{
        '.MuiDialog-paper': { maxWidth: 500, px: '24px', py: '12px', mx: 2 },
        '.MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root':
          { px: 0, py: '12px' },
        '.MuiDialogActions-root > :not(:first-of-type)': { ml: 0 },
      }}
      {...props}
    >
      <IconButton
        aria-label="close"
        sx={{
          color: 'text.primary',
          position: 'absolute',
          right: '16px',
          top: '16px',
        }}
        onClick={handleClose}
      >
        <CloseIcon sx={{ fontSize: 22 }} />
      </IconButton>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          gap: 2,
          paddingBlock: '32px !important',
        }}
      >
        <Image
          data-test-id="imgVcanaycDraftAlreadyPublishedDialog"
          src="/illustrations/character-12-illusatration.png"
          alt="hero"
          sx={{ width: '68px' }}
        />

        <Typography
          data-test-id="ctVcanaycDraftAlreadyPublishedDialogTitle"
          variant="h5"
          color="text.primary"
          sx={{ px: '20px' }}
        >
          {t('vacancy:draftAlreadyPublishedDialog.title')}
        </Typography>

        <DialogContentText
          data-test-id="ctVcanaycDraftAlreadyPublishedDialogBody"
          variant="body1"
          color="text.primary"
          sx={{ px: '20px' }}
        >
          {t('vacancy:draftAlreadyPublishedDialog.body')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default VacancyDraftAlreadyPublishedDialog;
